export default [
  {
    title: 'Todos',
    route: 'cubus-todo',
    icon: 'CheckSquareIcon',
  },
  {
    header: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . .',
  },
]
