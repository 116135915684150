export default [
  /*
  {
    title: 'Ribbon',
    route: 'cubus-ribbon',
    icon: 'CastIcon',
  },
  */
  {
    title: 'Dashboard',
    route: 'dashboard-cubus',
    icon: 'HomeIcon',
  },
  {
    title: 'Domains',
    route: 'cubus-domains',
    icon: 'LayersIcon',
  },
  {
    header: 'MENU',
  },
  {
    title: 'References',
    route: 'cubus-refs',
    icon: 'ListIcon',
  },
  {
    title: 'Data Tables',
    route: 'cubus-tables',
    icon: 'LayoutIcon',
  },
  {
    title: 'Cubes',
    route: 'cubus-cubes',
    icon: 'BoxIcon',
  },
  {
    title: 'Mounts',
    route: 'cubus-mounts',
    icon: 'GridIcon',
  },
  {
    title: 'Procedures',
    route: 'cubus-procedures',
    icon: 'PlayIcon',
  },
  {
    title: 'Reports',
    route: 'cubus-reports',
    icon: 'FileTextIcon',
  },
  {
    title: 'Imports',
    route: 'cubus-imports',
    icon: 'UploadCloudIcon',
  },
  /*
  {
    header: 'ROOT MENU',
  },
  {
    title: 'DataSources',
    route: 'cubus-datasources',
    icon: 'KeyIcon',
  },
  */
  {
    header: 'ADMIN MENU',
  },
  {
    title: 'Objects',
    route: 'cubus-objects',
    icon: 'BookIcon',
  },
  {
    header: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .',
  },
]
